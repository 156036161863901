exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-organizations-tsx": () => import("./../../../src/pages/pricing/organizations.tsx" /* webpackChunkName: "component---src-pages-pricing-organizations-tsx" */),
  "component---src-pages-pricing-professionals-tsx": () => import("./../../../src/pages/pricing/professionals.tsx" /* webpackChunkName: "component---src-pages-pricing-professionals-tsx" */),
  "component---src-pages-resources-faq-tsx": () => import("./../../../src/pages/resources/faq.tsx" /* webpackChunkName: "component---src-pages-resources-faq-tsx" */),
  "component---src-pages-solutions-organizations-tsx": () => import("./../../../src/pages/solutions/organizations.tsx" /* webpackChunkName: "component---src-pages-solutions-organizations-tsx" */),
  "component---src-pages-solutions-professionals-tsx": () => import("./../../../src/pages/solutions/professionals.tsx" /* webpackChunkName: "component---src-pages-solutions-professionals-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

